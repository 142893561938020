.page-footer {
	font-size: $footer-font-size;
}

.footer-copyright {
	.version
	, .customize
	, .screenreader {
		display: inline-block;
		margin-left: 1rem;
	}

	a {
		color: inherit;
	}
}
