// Fix for Regions under Tabs
ul.tabs ~ div.row, .hide-tab-scrollbar ~ div.row {
	margin-bottom: 0;

	.ma-region {
		padding-top:0;
	}
}

@media #{$medium-and-up} {
	.tabs .ma-region.white .a-IRR-container {
		box-shadow: none;
	}

	.tabs .t-fht-thead {
		width:inherit!important;
	}
}

@media #{$small-and-down} {
	.ma-region.white {
		.a-IRR-container {
			padding: 20px;
		}

		table.table-responsive tr {
			box-shadow: none;
		}
	}
}
