@media #{$small-and-down} {
    table.table-responsive thead {
        display: none;
    }

    table.table-responsive tr {
        padding: 5px;
        display: block;
        margin: .5rem 0 1rem;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
    }

    table.table-responsive td {
        display: block;
        text-align: right;
        border-bottom: 1px solid #eee;
    }

    table.table-responsive td:last-child {
        border-bottom: 0;
    }

    table.table-responsive td:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
    }
}

table {
    thead th {
        font-size: 15px;
    }

    td {
        input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime], input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea.materialize-textarea, .select-wrapper input.select-dropdown {
			&:not(.browser-default) {
            	margin-bottom: 0;
			}
        }
	}

	tbody tr:last-child {
		border-bottom: 0;
	}

	&.ma-table-small {
		td, th {
			padding: 5px;
		}
	}
}

// standard reports
.u-Report--dataLoad {
    td, th {
        overflow: visible;
        min-width: 125px;
        max-width: none;
    }
}

.u-Report tr:nth-child(even) {
    td, th[scope=row] {
        background-color: #fff;
    }
}

.u-Report--standardLook {
    td, th {
        padding: 8px;
    }
}

.u-Report--dataLoad, .u-Report--standardLook {
    line-height: inherit;
    font-size: inherit;
}

// calendar fix
.fc thead {
  border-bottom: none;
}
