$sidenav-height: 44px;

#sidenav-overlay {
	z-index: 999;
}

.drag-target {
	z-index: 1000;
}

.sidenav {
	z-index: 1000;
	height: 100%;
	width: $sidenav-width;

	// Right Align
	&.right-aligned {
		right: initial;
		left: initial;
	}

	li>a {
		padding: 0 16px;
	}

	.collapsible {
		.collapsible-body {
			background-color: $sidenav-bg-color;
			padding: 0;
		}
	}
}

/*
Sidebar Fixed
*/
.ma-sidenav-fixed {
	.sidenav {
		@extend .sidenav-fixed;
	}

	@media #{$large-and-up} {
		#app-sidenav-trigger {
			display: none !important;
		}

		.top-nav-wrapper {
			z-index: 1001;
		}

		.sidenav {
			transform: translateX(0) !important;
			z-index: 1001;
		}

		main,
		footer,
		.main-nav-wrapper,
		.search-nav-wrapper,
		.nav-breadcrumbs,
		.nav-content
		{
			padding-left: $sidenav-width;
		}

		.container,
		&.main-container .ma-main-container {
			width: 85%;
		}
	}

	@media #{$medium-and-down} {
		#app-sidenav-trigger {
			display: block !important;
		}

		main,
		footer {
			padding-left: 0;
		}
	}
}

/* SideNav Header */
.user-view {
	@include hide();

	a,
	span {
		color: $sidenav-header-text-color;
	}

	a {
		padding: 0;
		line-height: 24px;
		height: auto;
	}
}

.sidenav .user-view {
	@include showBlock();
}
