.ui-datepicker-trigger {
	margin-left: -32px!important;
	background-color: transparent;
	border: none;
	box-shadow: none;
	padding: 4px 8px;
	line-height: 12px;
}

.a-IG .ui-datepicker-trigger {
	margin-left: -1px;
}

.ui-datepicker {
	padding: 0;
	border: none;
	z-index: 800!important;
	width: auto;
	@include z-depth-5;

	.ui-datepicker-material {
		display: flex;
	}

	.ui-datepicker-material-header {
		background-color: $datepicker-date-bg;
		color: #fff;
		padding: 1rem;
		font-weight: 300;
		max-width: 200px;

		.ui-datepicker-material-year {
			font-size: 1.3rem;
			color: $datepicker-year;
		}

		.ui-datepicker-material-day {
			font-size: 2rem;
		}
	}

	.ui-datepicker-material-body {
		display: block;
		min-width: 250px;
	}

	.ui-datepicker-header {
		text-align: center;
		background: #fff;
		font-weight: 300;
		padding: 0;
		border: none;

		.ui-datepicker-title {
			margin: .3rem 0;
			height: 2.3rem;

			select.ui-datepicker-month, select.ui-datepicker-year {
				@extend .browser-default;
				display: inline-block!important;
				margin: 0 0.5rem;
				width: 25%;
			}

			span.ui-datepicker-month, span.ui-datepicker-year {
				display: inline-block;
				margin: 1rem 0;
			}
		}

		.ui-datepicker-prev, .ui-datepicker-next {
			top: auto;
			height: 4rem;
			width: 3rem;
			cursor: pointer;

			.ui-icon {
				background-image: none!important;

				&:before {
					content: " ";
					border-top: .5em solid transparent;
					border-bottom: .5em solid transparent;
					border-right: 0.75em solid #676767;
					width: 0;
					height: 0;
					display: block;
					margin: 0 auto;
				}
			}

			&.ui-state-hover {
				border: none;
				background: $datepicker-selected-outfocus;
			}
		}

		.ui-datepicker-prev .ui-icon:before {
		}

		.ui-datepicker-next .ui-icon:before {
			border-right: 0;
			border-left: 0.75em solid #676767;
		}

		.ui-datepicker-prev, .ui-datepicker-prev-hover {
			left: 0;
		}

		.ui-datepicker-next, .ui-datepicker-next-hover {
			right: 0;
		}
	}

	.ui-datepicker-calendar {
		background: #fff;
		margin: 0;

		.ui-state-default {
			background: none;
			border: none;
			text-align: center;
			box-shadow: none;
			padding: .4rem 0;
		}

		.ui-state-active, .ui-state-highlight, .ui-state-hover {
			border-radius: 50%;
			color: #fff;
			width: 25px;
			height: 25px;
			margin: auto;
			line-height: 13px;
		}

		.ui-state-active {
			background-color: $datepicker-selected!important;
		}

		.ui-state-highlight, .ui-state-hover {
			color: $datepicker-date-bg;
		}

		thead {
			border-bottom: none;

			th {
				color: #999;
				font-weight: 500;
				width: percentage(1 / 7);
				padding: 0.5em 0;
			}
		}

		tr {
			border-bottom: 0;
		}
	}

	.ui-datepicker-buttonpane {
		.ui-corner-all {
			display: inline-block;

			.spanTime {
				table {
					margin-bottom: 0;
				}

				tr {
					border: none;
				}

				.datetimepicker_newMonth {
					display: inline-block;
					width: auto;
					margin-top: 0.2rem!important;
					margin-left: 0.2rem!important;
					margin-right: 0.2rem!important;
				}
			}
		}

		button {
			@include btn-flat;
			font-weight: normal;
		}
	}
}
