body .ui-dialog.ui-widget.utr {
	z-index: 1002!important;

	.utr-container__body {
		max-height: 70vh!important;
	}

	.utr-custom-css__header-buttons,
	.utr-group__header-buttons {
		display: inline;
	}

	.ui-accordion-header .ui-accordion-header-icon {
		position: absolute;
		left: .5em;
		top: 50%;
		margin-top: -8px;
	}

	.ui-accordion-icons {
		padding-left: 2.2em;
	}

	// select
	&.a-LiveTemplateOptions {
		.a-Property-field--select {
			width: 95%;
			padding-right: 0;
		}
	}

	// checkbox and radio
	.a-Property-checkbox-input[type="checkbox"], .a-Property-radio-input[type="radio"] {
		& + label {
			padding-left: 35px;

			&:before {
				background-color: transparent;
				border: none;
			}
			&:after {
				opacity: 1;
			}
		}

		// &:checked + label {
		//     &:before {
		//
		//     }
		//     &:after {
		//
		//     }
		// }

		&:focus:checked + label {
			&:before {
				border-color: transparent;
				background-color: transparent;
			}
			// &:after {
			//
			// }
		}

		&:hover, &:focus {
			& + label:before {
				border-color: transparent;
				box-shadow: none;
			}

			& + label:after {
				transform: inherit;
			}
		}
	}

	// Footer
	.ui-dialog-buttonpane {
		margin-top: 0;
		padding: 0;

		.ui-dialog-buttonset {
			.ui-button {
				@extend .btn-flat;
				background: none;
			}

			.ui-button.ui-button--hot {
				@extend .btn;
			}
		}
	}

	.utr-toolbar-search {
		border-bottom: none;
		height: 30px;
		width: 30px;
		padding: 0;

		&:hover {
			width: 100px;
		}
	}

	button:focus {
		background-color:transparent;
	}

	.utr-container__field--select__container select {
		display:block;
		height:auto;
		padding: 0;
	}

	.a-D3ColorPicker-control {
		width: 4rem;
		height: auto;
		margin: 1px;
	}

	.ui-dialog-titlebar {
		.ui-dialog-titlebar__minimize {
			right: 30px!important;
			top: 6px!important;
		}

		.ui-dialog-titlebar-close {
			right: 10px!important;
			top: 10px!important;

			.ui-icon-closethick {
				display: block;
			}
		}
	}

	label[for='utr_theme_name'] {
		@include hide;
	}

	.icon-tr-close {
		@include hide;
	}
}
