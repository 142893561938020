// custom responsive text (inspired by flow-text)
.ma-responsive-text{
  $i: 0;

  @while $i <= $intervals {
    @media only screen and (min-width : 360 + ($i * $interval-size)) {
      font-size: 1.2rem * (1 + (.03 * $i)) !important;
    }
    $i: $i + 1;
  }

  // Handle below 360px screen
  @media only screen and (max-width: 360px) {
    font-size: 1.2rem !important;
  }
}
