// Search nav
.nav-wrapper,
.nav-wrapper form,
.nav-wrapper form .input-field {
	height: 100%;
}

nav .search-nav-wrapper .input-field {
	height: $navbar-height-mobile;
}

@media #{$medium-and-up} {
	nav .search-nav-wrapper .input-field {
		height: $navbar-height;
	}
}

nav .search-nav-wrapper .input-field label.active {
	transform: translateY(0);
	left: 1rem;
}

// Search nav
.page-navbar-fixed .top-nav-wrapper {
	@extend .navbar-fixed;
}

nav {
	background-color: $navbar-bg-color;
	color: $navbar-font-color;
}

nav ul a {
	display: inline-block;
}

nav .nav-wrapper {
	li, i {
		height: $navbar-height-mobile;
		line-height: $navbar-height-mobile;
	}

	i {
		font-size: ($navbar-font-size * 1.25) !important;
	}
}

@media #{$medium-and-up} {
	nav .nav-wrapper {
		li, i {
			height: $navbar-height;
			line-height: $navbar-height;
		}

		i {
			font-size: ($navbar-font-size * 1.75) !important;
		}
	}
}

nav .nav-wrapper {
	.btn i {
		height: $button-height;
		line-height: $button-height;
		font-size: $button-icon-font-size !important;
	}
}

.drag-target {
	top: $navbar-height-mobile;
}

@media #{$medium-and-up} {
	.drag-target {
		top: $navbar-height;
	}
}

.page-left-logo nav .brand-logo {
	left: auto;
	transform: none;
}

.page-center-logo nav .brand-logo {
	@extend .center;
}

@media #{$small-and-down} {
	.page-center-logo nav .brand-logo {
		left: auto;
		transform: none;
	}
}

.navbar-list .dropdown-content {

	li,
	a,
	i,
	span {
		height: $navbar-height-mobile;
		line-height: $navbar-height-mobile;
		padding-top: 0;
		padding-bottom: 0;
	}
}

@media #{$medium-and-up} {
	.navbar-list .dropdown-content {

		li,
		a,
		i,
		span {
			height: $navbar-height;
			line-height: $navbar-height;
		}
	}
}

// Scrollspy
.apex-rds {
	@extend .table-of-contents;
}

.apex-rds-slider {
	@include hide;
}

// Region Display Selector
.apex-rds-selected a {
	font-weight: 500;
	padding-left: 18px;
	border-left: 2px solid $secondary-color;
}

// Button
nav .button-collapse {
	margin: 0;
}

// Transparent navbar
.ma-page-navbar--transparent {
	header {
		position: absolute;

		nav:not(.ma-no-transparency) {
			background-color: transparent;
			box-shadow: none;
			transition: all 0.5s linear;

			a {
				color: $navbar-font-color-transparent;
			}

			.btn {
				color: $button-raised-color;
			}
		}

		nav.ma-no-transparency {
			background-color: $navbar-bg-color;
			@extend .z-depth-1;

			a {
				color: $navbar-font-color;
			}

			.btn {
				color: $button-raised-color;
			}
		}

		nav.ma-no-transparency.scroll {
			transition: all 0.5s linear;
		}
	}
}

// Dropdown content
header .dropdown-content {
	li {
		&>a,
		&>span {
			color: $dropdown-color;
		}
	}
}

// Tabs
header .nav-content {
	.tabs.tabs-transparent .tab a {
		color: $navbar-font-color;
	}

	.tabs.tabs-transparent .tab a:hover
	, .tabs.tabs-transparent .tab a.active {
		color: $navbar-font-color;
		border-bottom: 2px solid $navbar-font-color;
	}

	.indicator {
		@include hide;
	}
}

// Logo
nav .brand-logo {
	font-weight: lighter;
}
