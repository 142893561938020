.collapsible {
	margin: 0;

	.collapsible-header i {
		width: initial;
	}

	.collapsible-body {
		background-color: $collapsible-header-color;
	}
}
