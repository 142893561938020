.modal, .t-Dialog-page {
	background-color: $modal-background-color;

	.fixed-action-btn {
		bottom: 23px;
	}

	&.bottom-sheet {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		display: block;
	}

	.t-Dialog-body {
		padding: 1rem;
	}

	.t-Dialog-footer {
		padding: 0 1rem;

		// remove unnessessary margin on modal footers
		.ma-region {
			margin-bottom: 0;
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

// Strech modals
// Okay that's a funny hack...
// The UT modal mecanism is hardcoding it's strech modal to 95%
.ui-dialog--apex[style*="height: 95%; width: 95%;"] {
    width: 100%!important;
    height: 100%!important;
    top: 0!important;
    left: 0!important;
}

.region.modal {
	&.bottom-sheet {
		top: auto;
		bottom: -100%;
		width: 100%;

		.modal-footer {
			display: none;
		}
	}
}

body {
	.ui-widget-overlay {
		z-index: 999;
	}

	.ui-dialog.ui-front {
		border-width: 0;
		border-radius: 2px;
		position: absolute;
		top: 0;
		left: 0;
		padding: 0;
		outline: 0;
		box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
		box-sizing: content-box;
		background-color: $modal-background-color;
		z-index: 1000;
		background-clip: padding-box;
		transform: translate(0);
		will-change: top, opacity;

		.ui-dialog-titlebar {
			background-color: $modal-header-background;
			background: none;

			.ui-dialog-title {
				@extend h4;
				color: $modal-header-text;
				margin-top: 0;
				margin-bottom: 0;
			}
		}

		.ui-dialog-titlebar-close {
			right: 16px;
			top: 16px;
			box-shadow: none;
			background: none;
			border:none;

			&:before {
				font-size: 2rem;
			}

			.ui-icon-closethick {
				// display: none;
			}
		}
	}
}

body .ui-dialog, body .ui-dialog .ui-widget-content {
	background: none;

	.ui-state-focus {
		box-shadow: none!important;
	}
}

body .ui-dialog .ui-dialog-content, .t-Dialog-page {
	background-color: $modal-background-color;
}
