/*
{
  "translate": false,
  "groups":[
  {
    "name": "Global",
    "common": true,
    "sequence": 1
  },
  {
    "name": "Button",
    "common": true,
    "sequence": 2
  },
  {
    "name": "Card",
    "common": true,
    "sequence": 3
  },
  {
    "name": "Form",
    "common": true,
    "sequence": 4
  },
  {
    "name": "Table",
    "common": true,
    "sequence": 5
  },
  {
    "name": "Badge",
    "common": false,
    "sequence": 6
  },
  {
    "name": "Carousel",
    "common": false,
    "sequence": 7
  },
    "name": "Collapsible",
    "common": false,
    "sequence": 8
  },
  {
    "name": "Chips",
    "common": false,
    "sequence": 9
  },
  {
    "name": "Dropdown",
    "common": false,
    "sequence": 10
  },
  {
    "name": "Top Navigation",
    "common": false,
    "sequence": 11
  },
  {
    "name": "Side Navigation",
    "common": false,
    "sequence": 12
  },
  {
    "name": "Slider",
    "common": false,
    "sequence": 13
  },
  {
    "name": "Tab",
    "common": false,
    "sequence": 14
  },
  {
    "name": "Toast",
    "common": false,
    "sequence": 15
  },
  {
    "name": "Footer",
    "common": false,
    "sequence": 16
  },
  {
    "name": "Collection",
    "common": false,
    "sequence": 17
  },
  {
    "name": "Modal",
    "common": false,
    "sequence": 18
  }
  ]
}
*/

/* ===============================================================
1- Global
=============================================================== */
/*
{
   "var": "@background-color",
   "name": "Body Background",
   "type": "color",
   "style": "big",
   "group": "Global"
}
*/
$background-color: #eee;
/*
{
    "var": "@primary-color",
    "name": "Primary",
    "type": "color",
    "style": "big",
    "group": "Global"
}
*/
$primary-color: #e91e63;
$primary-color-light: lighten($primary-color, 15%) !default;
$primary-color-dark: darken($primary-color, 15%) !default;
/*
{
    "var": "@secondary-color",
    "name": "Secondary",
    "type": "color",
    "style": "big",
    "group": "Global"
}
*/
$secondary-color: #e91e63;
/*
{
    "var": "@success-color",
    "name": "Background",
    "type": "color",
    "group": "Global",
    "subgroup": "Success"
}
*/
$success-color: #81c784;
/*
{
    "var": "@success-color-text",
    "name": "Text",
    "type": "color",
    "group": "Global",
    "subgroup": "Success"
}
*/
$success-color-text: #fff;
/*
{
    "var": "@warning-color",
    "name": "Warning",
    "type": "color",
    "group": "Global",
    "subgroup": "Warning"
}
*/
$warning-color: #fef7e0;
/*
{
    "var": "@warning-color-text",
    "name": "Text",
    "type": "color",
    "group": "Global",
    "subgroup": "Warning"
}
*/
$warning-color-text: #303030;
/*
{
    "var": "@error-color",
    "name": "Error",
    "type": "color",
    "group": "Global"
}
*/
$error-color: #F44336;
/*
{
    "var": "@link-color",
    "name": "Link",
    "type": "color",
    "group": "Global"
}
*/
$link-color: #039be5;

/* ===============================================================
End Global
=============================================================== */

/* ===============================================================
2- Buttons
 ============================================================== */

$button-border: none !default;
$button-background-focus: lighten($secondary-color, 4%) !default;
/*
{
   "var"  : "@button-font-size",
   "name" : "Font Size",
   "type" : "number",
   "units": "rem",
      "range": {
      "min": 0,
      "max": 5,
      "increment": 0.1
   },
   "group": "Button"
}
*/
$button-font-size: 1.0rem !default;
/*
{
   "var"  : "@button-icon-font-size",
   "name" : "Font Size - Icon",
   "type" : "number",
   "units": "rem",
       "range": {
       "min": 0,
       "max": 5,
       "increment": 0.1
   },
   "group": "Button"
}
*/
$button-icon-font-size: 1.3rem !default;
/*
{
   "var"  : "@button-height",
   "name" : "Height",
   "type" : "number",
   "units": "px",
       "range": {
       "min": 0,
       "max": 100,
       "increment": 2
   },
   "group": "Button"
}
*/
$button-height: 36px !default;
$button-padding: 0 2rem !default;
/*
{
   "var"  : "@button-radius",
   "name" : "Radius",
   "type" : "number",
   "units": "px",
       "range": {
       "min": 0,
       "max": 100,
       "increment": 2
   },
   "group": "Button"
}
*/
$button-radius: 2px !default;
$button-disabled-background: #DFDFDF !default;
$button-disabled-color: #9F9F9F !default;

// Raised buttons
/*
{
   "var"  : "@button-raised-background",
   "name" : "Background",
   "type" : "color",
   "group": "Button",
   "subgroup": "Default Button Colors"
}
*/
$button-raised-background: $secondary-color !default;
$button-raised-background-hover: lighten($button-raised-background, 5%) !default;
/*
{
   "var"  : "@button-raised-color",
   "name" : "Text",
   "type" : "color",
   "group": "Button",
   "subgroup": "Default Button Colors"
}
*/
$button-raised-color: #fff !default;
$button-large-icon-font-size: 1.6rem !default;
$button-large-height: $button-height * 1.5 !default;

/*
{
   "var"  : "@button-floating-background",
   "name" : "Background",
   "type" : "color",
   "group": "Button",
   "subgroup": "Floating Button Colors"
}
*/
$button-floating-background: $secondary-color !default;
$button-floating-background-hover: $button-floating-background !default;
/*
{
   "var"  : "@button-floating-color",
   "name" : "Text",
   "type" : "color",
   "group": "Button",
   "subgroup": "Floating Button Colors"
}
*/
$button-floating-color: #fff !default;
$button-floating-size: 37px !default;
$button-floating-large-size: $button-floating-size * 1.5 !default;
$button-floating-radius: 50% !default;

/*
{
   "var"  : "@button-flat-color",
   "name" : "Flat Button Text",
   "type" : "color",
   "group": "Button"
}
*/
$button-flat-color: #343434 !default;
$button-flat-disabled-color: lighten(#999, 10%) !default;

// Large buttons
$button-large-font-size: 15px !default;
$button-large-icon-font-size: 1.6rem !default;
$button-large-height: $button-height * 1.5 !default;
$button-floating-large-size: 56px !default;

// Small buttons
$button-small-font-size: 13px !default;
$button-small-icon-font-size: 1.2rem !default;
$button-small-height: $button-height * .9 !default;
$button-floating-small-size: $button-height * .9 !default;

/* ===============================================================
End Buttons
=============================================================== */

/* ===============================================================
3- Cards
=============================================================== */
/*
{
    "var"  : "@card-padding",
    "name" : "Padding",
    "type" : "number",
    "units": "px",
    "range": {
    "min": 0,
    "max": 100,
    "increment": 1
    },
    "group": "Card"
}
*/
$card-padding: 20px !default;
/*
{
    "var"  : "@card-bg-color",
    "name" : "Background",
    "type" : "color",
    "group": "Card",
    "subgroup": "Card Colors"

}
*/
$card-bg-color: #fff !default;
/*
{
    "var"  : "@card-link-color",
    "name" : "Text",
    "type" : "color",
    "group": "Card",
    "subgroup": "Card Colors"
}
*/
$card-link-color: #ffab40;
$card-link-color-light: lighten($card-link-color, 20%) !default;

/* ===============================================================
End Cards
=============================================================== */

/* ===============================================================
4- Forms
=============================================================== */

/*
{
  "var"  : "@input-height",
  "name" : "Input - Height",
  "type" : "number",
  "units": "rem",
  "range": {
    "min": 1,
    "max": 5,
    "increment": 1
  },
  "group": "Form"
}
*/
$input-height: 3rem !default;
/*
{
  "var" : "@input-border-color",
  "name" : "Input - Border",
  "type" : "color",
  "group": "Form"
}
*/
$input-border-color: #9e9e9e;
$input-border: 1px solid $input-border-color !default;
/*
{
  "var" : "@input-background",
  "name" : "Input - Background",
  "type" : "color",
  "group": "Form"
}
*/
$input-background: #fff !default;
$input-error-color: $error-color !default;
$input-success-color: $success-color !default;
$input-focus-color: $secondary-color !default;
/*
{
  "var"  : "@input-font-size",
  "name" : "Input - Font Size",
  "type" : "number",
  "units": "rem",
  "range": {
    "min": 1,
    "max": 5,
    "increment": 0.1
  },
  "group": "Form"
}
*/
$input-font-size: 1rem !default;
$input-margin-bottom: 20px;
$input-margin: 0 0 20px 0 !default;
$input-padding: 0 !default;
$input-transition: all .3s !default;
/*
{
  "var"  : "@label-font-size",
  "name" : "Label - Font Size",
  "type" : "number",
  "units": "rem",
  "range": {
    "min": 0,
    "max": 3,
    "increment": 0.1
  },
  "group": "Form"
}
*/
$label-font-size: .8rem !default;
$input-disabled-color: rgba(0,0,0, .26) !default;
$input-disabled-solid-color: #BDBDBD !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
$input-icon-size: 2rem;
$placeholder-text-color: lighten($input-border-color, 20%) !default;

// Radio Buttons
$radio-fill-color: $secondary-color !default;
$radio-empty-color: #5a5a5a !default;
$radio-border: 2px solid $radio-fill-color !default;

// Range
/*
{
  "var"  : "@range-height",
  "name" : "Range - Height",
  "type" : "number",
  "units": "px",
  "range": {
    "min": 1,
    "max": 30,
    "increment": 1
  },
  "group": "Form"
}
*/
$range-height: 14px !default;
/*
{
  "var"  : "@range-width",
  "name" : "Range - Width",
  "type" : "number",
  "units": "px",
  "range": {
    "min": 1,
    "max": 30,
    "increment": 1
  },
  "group": "Form"
}
*/
$range-width: 14px !default;
$track-height: 3px !default;

// Select
$select-border: 1px solid #f2f2f2 !default;
$select-background: rgba(255, 255, 255, 0.90) !default;
$select-focus: 1px solid lighten($secondary-color, 47%) !default;
$select-option-hover: rgba(0, 0, 0, .08) !default;
$select-option-focus: rgba(0, 0, 0, .08) !default;
$select-option-selected: rgba(0, 0, 0, .03) !default;
$select-padding: 5px !default;
$select-radius: 2px !default;
$select-disabled-color: rgba(0, 0, 0, .3) !default;

// Switches
$switch-bg-color: $secondary-color !default;
$switch-checked-lever-bg: desaturate(lighten($secondary-color, 25%), 25%) !default;
$switch-unchecked-bg: #F1F1F1 !default;
$switch-unchecked-lever-bg: #818181 !default;
$switch-radius: 15px !default;

/* ===============================================================
End Forms
=============================================================== */

/* ===============================================================
5- Table
 ============================================================== */

/*
{
"var" : "@table-border-color",
"name" : "Border",
"type" : "color",
"group": "Table"
}
*/
$table-border-color: #d0d0d0 !default;
/*
{
 "var" : "@table-striped-color",
 "name" : "Hover",
 "type" : "color",
 "group": "Table"
}
*/
$table-striped-color: #f2f2f2 !default;

/* ===============================================================
End Table
 ============================================================== */

/* ===============================================================
6- Badge
============================================================== */

/*
{
    "var": "@badge-bg-color",
    "name": "Background",
    "type": "color",
    "group": "Badge"
}
*/
$badge-bg-color: $secondary-color !default;
/*
{
    "var"  : "@badge-height",
    "name" : "Height",
    "type" : "number",
    "units": "px",
    "range": {
    "min": 0,
    "max": 50,
    "increment": 1
    },
    "group": "Badge"
}
*/
$badge-height: 22px !default;

/* ===============================================================
End Badge
=============================================================== */

/* ===============================================================
7- Carousel
 ============================================================== */

/*
{
    "var"  : "@carousel-height",
    "name" : "Height",
    "type" : "number",
    "units": "px",
    "range": {
    "min": 0,
    "max": 1000,
    "increment": 10
    },
    "group": "Carousel"
}
*/
$carousel-height: 400px !default;
$carousel-item-height: $carousel-height / 2 !default;
$carousel-item-width: $carousel-item-height !default;

/* ===============================================================
End Carousel
=============================================================== */

/* ===============================================================
8- Collasible
 ============================================================== */

/*
{
    "var"  : "@collapsible-height",
    "name" : "Height",
    "type" : "number",
    "units": "rem",
    "range": {
    "min": 0,
    "max": 10,
    "increment": 1
    },
    "group": "Collapsible"
}
*/
$collapsible-height: 3rem !default;
$collapsible-line-height: $collapsible-height !default;
/*
{
    "var"  : "@collapsible-header-color",
    "name" : "Header",
    "type" : "color",
    "group": "Collapsible"
}
*/
$collapsible-header-color: #fff !default;
/*
{
    "var"  : "@collapsible-border-color",
    "name" : "Border",
    "type" : "color",
    "group": "Collapsible"
}
*/
$collapsible-border-color: #ddd !default;


/* ===============================================================
End Collapsible
=============================================================== */

/* ===============================================================
9- Chip
 ============================================================== */

/*
{
  "var" : "@chip-bg-color",
  "name" : "Background",
  "type" : "color",
  "group": "Chips"
}
*/
$chip-bg-color: #e4e4e4 !default;
$chip-border-color: #9e9e9e !default;
$chip-selected-color: #26a69a !default;
/*
{
  "var"  : "@chip-margin",
  "name" : "Margin",
  "type" : "number",
  "units": "px",
  "range": {
    "min": 0,
    "max": 25,
    "increment": 1
  },
  "group": "Chips"
}
*/
$chip-margin: 5px !default;

/* ===============================================================
End Chip
=============================================================== */

/* ===============================================================
Date+Time Picker
 ============================================================== */

$datepicker-display-font-size: 2.8rem;
$datepicker-calendar-header-color: #999;
$datepicker-weekday-color: rgba(0, 0, 0, .87) !default;
$datepicker-weekday-bg: darken($secondary-color, 7%) !default;
$datepicker-date-bg: $secondary-color !default;
$datepicker-year: rgba(255, 255, 255, .7) !default;
$datepicker-focus: rgba(0, 0, 0, .05) !default;
$datepicker-selected: $secondary-color !default;
$datepicker-selected-outfocus: desaturate(lighten($secondary-color, 35%), 15%) !default;
$datepicker-day-focus: transparentize(desaturate($secondary-color, 5%), .75) !default;
$datepicker-disabled-day-color: rgba(0, 0, 0, .3) !default;
$timepicker-clock-color: rgba(0, 0, 0, .87) !default;
$timepicker-clock-plate-bg: #eee !default;

/* ===============================================================
End Date Picker
=============================================================== */

/* ===============================================================
10- Dropdown
 ============================================================== */

/*
{
  "var" : "@dropdown-bg-color",
  "name" : "Background",
  "type" : "color",
  "group": "Dropdown",
  "subgroup": "Dropdown Colors"
}
*/
$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
/*
{
  "var" : "@dropdown-color",
  "name" : "Text",
  "type" : "color",
  "group": "Dropdown",
  "subgroup": "Dropdown Colors"
}
*/
$dropdown-color: $secondary-color !default;
$dropdown-item-height: 50px !default;


/* ===============================================================
End Dropdown
=============================================================== */

/* ===============================================================
Media Queries
 ============================================================== */

$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

/* ===============================================================
End Media Queries
=============================================================== */

/* ===============================================================
Grid
 ============================================================== */

$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;

/* ===============================================================
End Grid
=============================================================== */

/* ===============================================================
11- Top Navigation
 ============================================================== */

/*
{
	"var" : "@navbar-bg-color",
	"name" : "Background",
	"type" : "color",
	"group": "Top Navigation",
	"subgroup": "Colors"
}
*/
$navbar-bg-color: #fff !default;
/*
{
	"var" : "@navbar-font-color",
	"name" : "Text",
	"type" : "color",
	"group": "Top Navigation",
	"subgroup": "Colors"
}
*/
$navbar-font-color: #555 !default;

/*
{
	"var" : "@navbar-font-color-transparent",
	"name" : "Text Color (On Transparent Navbar)",
	"type" : "color",
	"group": "Top Navigation"
}
*/
$navbar-font-color-transparent: #fff !default;

/*
{
 "var"  : "@navbar-height",
 "name" : "Height",
 "type" : "number",
 "units": "px",
 "range": {
   "min": 1,
   "max": 100,
   "increment": 1
 },
 "group": "Top Navigation"
}
*/
$navbar-height: 64px !default;
$navbar-line-height: $navbar-height !default;
/*
{
  "var"  : "@navbar-height-mobile",
  "name" : "Height (Mobile)",
  "type" : "number",
  "units": "px",
  "range": {
    "min": 1,
    "max": 100,
    "increment": 1
  },
  "group": "Top Navigation"
}
*/
$navbar-height-mobile: 56px !default;
$navbar-line-height-mobile: $navbar-height-mobile !default;
/*
{
  "var"  : "@navbar-font-size",
  "name" : "Font Size - Menu Entries",
  "type" : "number",
  "units": "rem",
  "range": {
    "min": 0.2,
    "max": 2,
    "increment": 0.2
  },
  "group": "Top Navigation"
}
*/
$navbar-font-size: 1rem !default;
/*
{
  "var"  : "@navbar-brand-font-size",
  "name" : "Font Size - Title",
  "type" : "number",
  "units": "rem",
  "range": {
    "min": 0.2,
    "max": 3,
    "increment": 0.2
  },
  "group": "Top Navigation"
}
*/
$navbar-brand-font-size: 2.1rem !default;

/* ===============================================================
End Top Navigation
=============================================================== */

/* ===============================================================
12- Side Navigation
 ============================================================== */

/*
{
 "var" : "@sidenav-header-text-color",
 "name" : "Header Text",
 "type" : "color",
 "group": "Side Navigation"
}
*/
$sidenav-header-text-color: #fff !default;

/*
{
 "var"  : "@sidenav-width",
 "name" : "Width",
 "type" : "number",
 "units": "px",
 "range": {
   "min": 100,
   "max": 500,
   "increment": 10
 },
 "group": "Side Navigation"
}
*/
$sidenav-width: 300px !default;
$sidenav-font-size: 14px !default;
/*
{
  "var" : "@sidenav-bg-color",
  "name" : "Background",
  "type" : "color",
  "group": "Side Navigation",
  "subgroup": "Menu Colors"
}
*/
$sidenav-bg-color: #fff !default;
/*
{
  "var" : "@sidenav-font-color",
  "name" : "Text",
  "type" : "color",
  "group": "Side Navigation",
  "subgroup": "Menu Colors"
}
*/
$sidenav-font-color: rgba(0,0,0,.87) !default;
$sidenav-padding: 16px !default;
$sidenav-item-height: 48px !default;
$sidenav-line-height: $sidenav-item-height !default;

/* ===============================================================
End Side Navigation
=============================================================== */

/* ===============================================================
13- Slider
 ============================================================== */
/*
{
  "var" : "@slider-bg-color",
  "name" : "Background",
  "type" : "color",
  "group": "Slider",
  "subgroup": "Colors"
}
*/
$slider-bg-color: #9e9e9e;
$slider-bg-color-light: lighten($slider-bg-color, 20%);
/*
{
  "var" : "@slider-indicator-color",
  "name" : "Text",
  "type" : "color",
  "group": "Slider",
  "subgroup": "Colors"
}
*/
$slider-indicator-color: #4CAF50;

/* ===============================================================
End Slider
=============================================================== */

/* ===============================================================
Spinner
 ============================================================== */

$spinner-default-color: $secondary-color !default;

/* ===============================================================
End Spinner
=============================================================== */

/* ===============================================================
14- Tab
 ============================================================== */

/*
{
 "var" : "@tabs-text-color",
 "name" : "Background",
 "type" : "color",
 "group": "Tab",
 "subgroup": "Colors"
}
*/
$tabs-text-color: $primary-color !default;
$tabs-underline-color: lighten($tabs-text-color, 15%) !default;
/*
{
 "var" : "@tabs-bg-color",
 "name" : "Text",
 "type" : "color",
 "group": "Tab",
 "subgroup": "Colors"
}
*/
$tabs-bg-color: #fff !default;

/* ===============================================================
End Tab
=============================================================== */

/* ===============================================================
15- Toast
 ============================================================== */

/*
{
 "var"  : "@toast-height",
 "name" : "Height",
 "type" : "number",
 "units": "px",
 "range": {
   "min": 1,
   "max": 100,
   "increment": 1
 },
 "group": "Toast"
}
*/
$toast-height: 48px !default;
/*
{
"var" : "@toast-color",
"name" : "Background",
"type" : "color",
"group": "Toast",
"subgroup": "Colors"
}
*/
$toast-color: #323232 !default;
/*
{
"var" : "@toast-text-color",
"name" : "Text",
"type" : "color",
"group": "Toast",
"subgroup": "Colors"
}
*/
$toast-text-color: #fff !default;
$toast-action-color: #eeff41;

/* ===============================================================
End Toast
=============================================================== */

// 20. Typography
// ==========================================================================
$font-stack: -apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Oxygen-Sans,
Ubuntu,
Cantarell,
"Helvetica Neue",
sans-serif !default;
$off-black: rgba(0, 0, 0, 0.87) !default;
// Header Styles
$h1-fontsize: 4.2rem !default;
$h2-fontsize: 3.56rem !default;
$h3-fontsize: 2.92rem !default;
$h4-fontsize: 2.28rem !default;
$h5-fontsize: 1.64rem !default;
$h6-fontsize: 1.15rem !default;

/* ===============================================================
End Typography
=============================================================== */

/* ===============================================================
16- Footer
 ============================================================== */

/*
{
   "var"  : "@footer-font-size",
   "name" : "Font Size",
   "type" : "number",
   "units": "rem",
       "range": {
       "min": 0,
       "max": 5,
       "increment": 0.1
   },
   "group": "Footer"
}
*/
$footer-font-size: 1.0rem !default;
/*
{
"var" : "@footer-bg-color",
"name" : "Background",
"type" : "color",
"group": "Footer",
"subgroup": "Top"
}
*/
$footer-bg-color: #232323 !default;
/*
{
"var" : "@footer-font-color",
"name" : "Text",
"type" : "color",
"group": "Footer",
"subgroup": "Top"
}
*/
$footer-font-color: #fff !default;
/*
{
"var" : "@footer-copyright-bg-color",
"name" : "Background",
"type" : "color",
"group": "Footer",
"subgroup": "Bottom"
}
*/
$footer-copyright-bg-color: rgba(51,51,51,.08) !default;
/*
{
"var" : "@footer-copyright-font-color",
"name" : "Text",
"type" : "color",
"group": "Footer",
"subgroup": "Bottom"
}
*/
$footer-copyright-font-color: rgba(255,255,255,.8) !default;

/* ===============================================================
End Footer
=============================================================== */

/* ===============================================================
Responsive Text
 ============================================================== */

$range : $large-screen - $small-screen !default;
$intervals: 20 !default;
$interval-size: $range / $intervals !default;

/* ===============================================================
End Responsive Text
=============================================================== */

/* ===============================================================
17- Collection
 ============================================================== */

/*
{
"var" : "@collection-border-color",
"name" : "Border",
"type" : "color",
"group": "Collection"
}
*/
$collection-border-color: #e0e0e0 !default;
/*
{
"var" : "@collection-bg-color",
"name" : "Background",
"type" : "color",
"group": "Collection",
"subgroup": "Collection Colors"
}
*/
$collection-bg-color: #fff !default;
$collection-active-bg-color: $secondary-color !default;
$collection-active-color: lighten($secondary-color, 55%) !default;
$collection-hover-bg-color: #ddd !default;
/*
{
"var" : "@collection-link-color",
"name" : "Text",
"type" : "color",
"group": "Collection",
"subgroup": "Collection Colors"
}
*/
$collection-link-color: $secondary-color !default;
/*
{
  "var"  : "@collection-line-height",
  "name" : "Line Height",
  "type" : "number",
  "units": "rem",
  "range": {
    "min": 1,
    "max": 5,
    "increment": 0.1
  },
  "group": "Collection"
}
*/
$collection-line-height: 1.5rem !default;

/* ===============================================================
End Collection
=============================================================== */

/* ===============================================================
Progress
 ============================================================== */

$progress-bar-color: $secondary-color !default;

/* ===============================================================
End Progress
=============================================================== */

/* ===============================================================
18- Modal
 ============================================================== */

/*
   {
   "var": "@modal-background-color",
   "name": "Background",
   "type": "color",
   "group": "Modal"
}
*/
$modal-background-color: #fafafa;
/*
{
   "var": "@modal-header-background",
   "name": "Background",
   "type": "color",
   "group": "Modal",
   "subgroup": "Header"
}
*/
$modal-header-background: $modal-background-color;
/*
{
   "var": "@modal-header-text",
   "name": "Text",
   "type": "color",
   "group": "Modal",
   "subgroup": "Header"
}
*/
$modal-header-text: #3d3d3d;
$modal-background-footer: $modal-background-color;

/* ===============================================================
End Modal
=============================================================== */
