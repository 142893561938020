// Input
.input-field {
	margin-top: 1.5rem;
}

:focus {
	outline: none;
}

// Date Pickers
.datepicker,
.u-TF-item--datepicker {
	cursor: pointer;
}

// Display image
.item-image-responsive img {
	@extend .responsive-img;
}

.item-image-circle img {
	@extend .circle;
}

.item-fade-in img {
	@extend .fade-in;
}

// Textarea
.apex-item-textarea-counter {
	float: right;
	font-size: 12px;
}

// Checkbox and radio
.checkbox_group,
.radio_group {
	margin-bottom: $input-margin-bottom;

	.apex-item-option {
		padding-right: 1rem;
	}
}

[type="checkbox"], [type="radio"] {
	&+label {
		@extend span;
	}
}

// Autocomplete
.apex-item-wrapper--auto-complete {
	label {
		transform: translateY(-14px) scale(0.8);
		transform-origin: 0 0;
	}

	.oj-inputsearch-choice {
		background-color: transparent;
		border: none;
		margin-top: 1rem;
	}

	.oj-inputsearch {
		max-width: 100%;
	}
}

.oj-listbox-drop {
	border: none;
	overflow: visible;

	ul.oj-listbox-results {
		li.oj-listbox-result {
			font-size: 16px;
			color: $primary-color;
			display: block;
			line-height: 22px;
			padding: 14px 16px;
		}
	}
}

// Popup LOV
.apex-item-wrapper--popup-lov {
	label {
		transform: translateY(-14px) scale(0.8);
		transform-origin: 0 0;
	}
}

.ma-popuplov-modal {
	margin: 0;
	max-width:100%;
	width:100%;

	#SEARCH {
		width: 50%;
	}

	.collection {
		margin-top: 0;
		margin-bottom: 0;

		br {
			@include hide();
		}

		a {
			@extend .collection-item;
		}
	}
}

.ma-popuplov {
	.lov input {
		border-bottom: 1px solid $input-border-color;
	}

	label {
		transform: translateY(0) scale(0.8);
		transform-origin: 0 0;
	}

	.ma-chevron-up:before {
		@include apex5-font();
		content: "\e012";
	}
}

// Validation
span.ma-error-message {
	color: $error-color;
	font-size: 0.8rem;
	position: absolute;
	top: 0;
}

.ma-field-container {
	span.ma-error-message {
		top: 3.5rem;
		left: 0;
	}

	&.ma-select-container span.ma-error-message {
		top: 5rem;
	}
}

// Icons
.input-field .prefix {
	font-size: 1.5rem;
	top: 0.5rem;
	text-align: center;
}

.input-field .prefix ~ label {
	width: auto;
}

.input-field .prefix ~ .display_only {
	margin-left: 3rem;
	top: 0.5rem;
	display: block;
	position: relative;
}

// No Label
.item-no-label {
	// hides regular input labels
	& > label {
		@include hide;
	}
}

// Switches
.switch label {
	position: relative;
}

.ma-switch-container {
	margin-top: 1.5rem;

	& > label {
		transform: translateY(-22px);
		transform-origin: 0 0;
		position: absolute;
	}
}

// Ranges
.input-field .range-field + label {
	transform: translateY(-14px) scale(0.8);
	transform-origin: 0 0;
}

input[type=range] {
	border: none;
}

// Text Alignment
.ma-field-container {
	position:relative;

	&.center-align,
	&.left-align,
	&.right-align {
		input:not(.select-dropdown),
		textarea {
			text-align: inherit;
		}
	}
}

// Labels
.label-block {
	display: block;
}

// Shuttle
.shuttle {
	width: 100%;
}

.shuttle table.shuttle {
	width: 100%;
	border-collapse: collapse;
}

.shuttle td {
	vertical-align: top;
}

.shuttle .shuttleControl,
.shuttle .shuttleSort2 {
	width: 1%;
}

.shuttle .shuttleSelect1,
.shuttle .shuttleSelect2 {
	width: 49%;
}

.shuttle select.shuttle_left,
.shuttle select.shuttle_right {
	width: 100%;
	min-height: 14.0rem;
	margin: 0.4rem 0;
	display: block;
}

// Quick picks in dropdowns
.apex-quick-picks {
	transform: translateY(-14px);
}

// Browser defaults
.apex-item-text.browser-default {
	@include browserDefaultInput();
}

.apex-item-select.browser-default {
	@include browserDefaultInput();
	@include browserDefaultSelect();
}
