.ma-region.container {
	@extend .container;
	margin-bottom: $input-margin-bottom; // this is to replace .row margin-bottom: $input-margin-bottom
}

.ma-region {
	margin-bottom: $input-margin-bottom; // this is to replace .row margin-bottom: $input-margin-bottom

	.ma-region--no-padding {
		.card-content {
			padding: 0;
		}
	}
}

.ma-container-90 {
	@include ma-container-90;
}

.ma-container-80 {
	@include ma-container-80;
}

.ma-container-70 {
	@include ma-container-70;
}

.row {
	margin-bottom: 0;

	.col {
		min-height: 0;
	}
}

// Debug styling
.grid-debug-on .row:before {
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	display: block;
	pointer-events: none;
}

.grid-debug-on .ma-container {
	position: relative;
}

.grid-debug-on .col:not(.col-null) {
	position: relative;
	box-shadow: 1px 1px 0 0 rgba(255, 0, 255, 0.5) inset, 1px 1px 0 0 rgba(255, 0, 255, 0.5);
}

.grid-debug-on .row {
	position: relative;
}

.grid-debug-on .row:before {
	background-image: linear-gradient(to left, transparent 0%, transparent 50%, rgba(255, 0, 255, 0.1) 50%, rgba(255, 0, 255, 0.1) 100%);
	background-size: 16.6666667% 100%;
	background-position: 50%;
}

.grid-debug-on .col:hover {
	opacity: 1;
	background-color: rgba(255, 0, 255, 0.15);
}
