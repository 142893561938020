@mixin hide() {
	display:none!important;
	visibility: hidden!important;
}

@mixin showBlock() {
	display:block!important;
	visibility:visible!important;
}

@mixin showFlex() {
	display:flex!important;
	visibility:visible!important;
}

@mixin showInlineBlock() {
	display:inline-block!important;
	visibility:visible!important;
}

@mixin browserDefaultInput() {
	background-color: transparent;
	border: none;
	border-bottom: none;
	border-radius: 0;
	outline: none;
	height: auto;
	width: auto;
	font-size: inherit;
	margin: 0;
	padding: 0;
	box-shadow: none;
	box-sizing: content-box;
	transition: none;
}

@mixin browserDefaultSelect() {
	display: block;
	width: 100%;
	height: $input-height;
	border: none;
	border-bottom: $input-border;
}

@mixin vh {
	border: 0!important;
	clip: rect(0 0 0 0)!important;
	height: 1px!important;
	margin: -1px!important;
	overflow: hidden!important;
	padding: 0!important;
	position: absolute!important;
	width: 1px!important;
}

@mixin ma-container-90 {
	margin: 0 auto;
	max-width: 1280px;
	width: 90%;

	@media #{$medium-and-up} {
		width: 90%;
	}

	@media #{$large-and-up} {
		width: 90%;
	}
}

@mixin ma-container-80 {
	margin: 0 auto;
	max-width: 1280px;
	width: 90%;

	@media #{$medium-and-up} {
		width: 85%;
	}

	@media #{$large-and-up} {
		width: 80%;
	}
}

@mixin ma-container-70 {
	margin: 0 auto;
	max-width: 1280px;
	width: 90%;

	@media #{$medium-and-up} {
		width: 80%;
	}

	@media #{$large-and-up} {
		width: 70%;
	}
}

@mixin ma-s1 {
	width: percentage(1 / 12 * 1);
}

@mixin ma-s2 {
	width: percentage(1 / 12 * 2);
}

@mixin ma-s3 {
	width: percentage(1 / 12 * 3);
}

@mixin ma-s4 {
	width: percentage(1 / 12 * 4);
}

@mixin ma-s5 {
	width: percentage(1 / 12 * 5);
}

@mixin ma-s6 {
	width: percentage(1 / 12 * 6);
}

@mixin ma-s7 {
	width: percentage(1 / 12 * 7);
}

@mixin ma-s8 {
	width: percentage(1 / 12 * 8);
}

@mixin ma-s9 {
	width: percentage(1 / 12 * 9);
}

@mixin ma-s10 {
	width: percentage(1 / 12 * 10);
}

@mixin ma-s11 {
	width: percentage(1 / 12 * 11);
}

@mixin ma-s12 {
	width: percentage(1 / 12 * 12);
}

@mixin apex5-font {
	font-size: 16px;
	font-family: apex-5-icon-font!important;
	font-style: normal!important;
	font-weight: 400!important;
	font-variant: normal!important;
	text-transform: none!important;
	speak: none;
	line-height: 1;
}

@mixin font-apex-small {
	font-family: 'Font APEX Small' !important;
}

@mixin font-apex-large {
	font-family: 'Font APEX Large' !important;
}

@mixin small-btn {
	height: 24px;
	line-height: 18px;
	padding: 0 1rem;
}

@mixin btn {
	@extend .btn;
}

@mixin btn-flat {
	@extend .btn-flat;
}

@mixin ma-card {
	@extend .card;
}

@mixin z-depth-1 {
	@extend .z-depth-1;
}

@mixin z-depth-2 {
	@extend .z-depth-2;
}

@mixin z-depth-3 {
	@extend .z-depth-3;
}

@mixin z-depth-4 {
	@extend .z-depth-4;
}

@mixin z-depth-5 {
	@extend .z-depth-5;
}

// Flexbox regions
@mixin ma-flex {
	display: flex;

	&.ma-flex-justify {
		justify-content: space-between;
	}

	& > .ma-flex-left, & > .ma-flex-right {
		align-self: center;

		&:empty {
			@include hide();
		}
	}

	& > .ma-flex-left {
		text-align: left;
		margin-right: 1rem;
	}

	& > .ma-flex-right {
		text-align: right;
		margin-left: 1rem;
	}

	& > .ma-flex-center {
		text-align: center;
	}

	& > .ma-flex-content {
		width: 100%;
		flex-shrink: 99999;
	}
}
