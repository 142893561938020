// Icons
.icon-float-left {
	// left icon
	i:first-child {
		margin-right: 0.5rem;
		vertical-align: top;
	}
	// right icon
	.ma-button-label + i {
		@include hide;
	}
}

.icon-float-right {
	// left icon
	i:first-child {
		@include hide;
	}
	// right icon
	.ma-button-label + i {
		margin-left: 0.5rem;
		vertical-align: top;
	}
}

.ma-button-icon:not(.btn-floating) {
	padding: 0 1rem;
}

// Spacing around Buttons
.ma-button {
    margin: 4px 2px;
}

// Fixed Action Button
.fab-relative {
	position: relative;
}

.fixed-action-btn {
	bottom: 45px;
	z-index: 996;

	&.fab-position-left {
		left: 25px;
	}

	&.fab-position-right {
		right: 25px;
	}

	&.fab-position-absolute {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	&.horizontal ul {
		bottom: 80px;

		li {
			margin: 5px 0 0;
		}

		.btn-floating {
			opacity: 0;
			margin: 5px 10px;
		}
	}

	/* Toolbar */
	&.fab-toolbar {
		&.active {
			& > a i {
				opacity: 0;
			}
		}
		padding: 0;
		height: $button-floating-large-size;

		ul {
			display: flex;
			top: 0;
			bottom: 0;

			li {
				flex: 1;
				display: inline-block;
				margin: 0;
				height: 100%;
				transition: none;

				button, a {
					display: block;
					overflow: hidden;
					position: relative;
					width: 100%;
					height: 100%;
					background-color: transparent;
					box-shadow: none;
					color: #fff;
					line-height: $button-floating-large-size;
					z-index: 1;
					margin: 0;

					i {
						line-height: inherit;
					}
				}
			}
		}
	}
}

/* Inline button
This is to give space as if there was a label */
.ma-btn-inline {
	margin-top: 2rem;
}

// IG and IRR buttons
.a-Button, .ui-button {
	&:focus {
		outline: none;
		background-color: transparent;
	}

    background-color: transparent;
    box-shadow: none;
}

// Button block
.ma-btn-block {
	display: block;
	width: 100%;
}

// Outlined
.btn.btn-outlined {
	border: 1px solid $button-disabled-color;
	background-color: transparent;
	color: $secondary-color;
	line-height: 2.25rem;

	&:not(:hover) {
		box-shadow: none;
	}
}
