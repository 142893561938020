.promo {
	width: 100%;

	i {
		  color: $primary-color;
		  font-size: 7rem;
		  display: block;
	}

	.promo-caption {
		font-size: 1.7rem;
		font-weight: 300;
		margin-top: 5px;
		margin-bottom: 0;
	}
}
